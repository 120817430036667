/**
 * @file It contains scripts for roles overview.
 */
import { mapGetters } from "vuex"
import { getListOfStrings } from "@/utils"

export default {
  name: "Roles",
  data() {
    return {
      filters: undefined,
      search : undefined
    }
  },
  methods: {
    /**
     * This method is used custom filtering for vuetify data table.
     * This will be called for every cell value in the table.
     * @param {*} value is content of each cell in a the table
     */
    customFilterForTable(value) {
      return this.filters.findIndex(element => value?.toString().includes(element)) + 1
    }
  },
  computed: {
    ...mapGetters({
      roles         : "roles/roles",
      isLoadingRoles: "roles/isLoadingRoles"
    }),
    rolesForTable: function() {
      return this.roles.map(role => {
        const applicable   = role.global ? this.$t("82") : (role.issue ? this.$t("83") : "")
        const roleForTable = {
          name       : role.name,
          description: role.description,
          applicable
        }
        return roleForTable
      })
    },
    headersForTable() {
      return this.$TABLES.ROLES.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    footersForTable() {
      return {
        ...this.$TABLES.ROLES.footer, ...{
          itemsPerPageText: this.$t(this.$TABLES.ROLES.footer.itemsPerPageText)
        }
      }
    },
    itemsForSearch() {
      return getListOfStrings(this.rolesForTable)
    }
  },
  watch: {
    filters: function(newFilters) {
      this.search = newFilters ? newFilters.toString() : undefined
    }
  }
}
